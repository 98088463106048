<template>
  <div id="myChart" :style="{width: '100%', height: '380px'}"></div>
</template>
<script>
import { getThemeSecretHistory } from "@/api/lgsecret";
import * as echarts from 'echarts'

  export default {
    
    data() {
      return {
        miId:'',
        keyword:''
      };
    },
    mounted(){
this.search()
    },
    methods: {
      
      async search(){
        this.keyword = this.$route.query.keyword;
      this.miId = this.$route.query.miId;
           this.loading = true;
        await getThemeSecretHistory({miId: this.miId})
          .then((res) => {
            this.loading = false;
            if (res.code == 1000) {
// 提取X轴数据和Y轴数据
let data = res.data
let xData = data.map(item => item.recordOn.slice(5,10));
let clickNum = data.map(item => item.clickNum);
let orderNum = data.map(item => item.orderNum);
let ygGmv = data.map(item => item.ygGmv);
let ygFee = data.map(item => item.ygFee);
var chartDom = document.getElementById("myChart");
      let myChart = echarts.init(chartDom);    
     // 使用ECharts的配置项进行配置
let option = {
  title: {
    text: this.keyword + '-密令词历史数据'
  },
  tooltip: {
					trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
					axisPointer: {// 坐标轴指示器，坐标轴触发有效
						type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
					}
},
  legend: {
    data: ['点击量', '有效订单量', '有效订单金额','预估收入'],
    top:"6%"
  },
  xAxis: {
    type: 'category',
    data: xData,
    axisLabel:{
            show:true,  //这里的show用于设置是否显示x轴下的字体 默认为true
　　　　　　　interval:0,  //可以设置成 0 强制显示所有标签。如果设置为 1，表示『隔一个标签显示一个标签』，如果值为 2，表示隔两个标签显示一个标签，以此类推。
　　
　　　　　　　textStyle:{   //textStyle里面写x轴下的字体的样式
               color:'#333',
               fontSize:13
            },
            rotate:45
       },
    axisLine:{
           show:true,  //这里的show用于设置是否显示x轴那一条线 默认为true
           lineStyle:{ //lineStyle里面写x轴那一条线的样式
             color:'#6FC6F3',
             width:2,    //轴线的粗细 我写的是2 最小为0，值为0的时候线隐藏
           }
       }
  },
  yAxis: {
    type: 'value',
       //用于设置y轴的字体
    axisLabel:{
            show:true,  //这里的show用于设置是否显示y轴下的字体 默认为true
            textStyle:{   //textStyle里面写y轴下的字体的样式
               color:'#333',
               fontSize:13
            }
       },
     //用于设置y轴的那一条线
    axisLine:{
           show:true,  //这里的show用于设置是否显示y轴那一条线 默认为true
           lineStyle:{ //lineStyle里面写y轴那一条线的样式
             color:'#6FC6F3',
             width:2,    //轴线的粗细 我写的是2 最小为0，值为0的时候线隐藏
           }
       }
  },
  dataZoom: [{
		show: true,
		endValue: 9//x轴少于10个数据，则显示全部，超过10个数据则显示前10个。
	}],
   series: [
    {
      name: '点击量',
      type: 'line',
      data: clickNum,
      smooth: true,
    },
    {
      name: '有效订单量',
      type: 'line',
      data: orderNum,
      smooth: true,
    },
    {
      name: '有效订单金额',
      type: 'line',
      data: ygGmv,
      smooth: true,
    },
    {
      name: '预估收入',
      type: 'line',
      data: ygFee,
      smooth: true,
    }
  ]
};

myChart.setOption(option);
          }else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      })
    },
  
    }
  };
</script>

